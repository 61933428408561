.button {
    border: none;
    cursor: pointer;
    position: absolute;
    padding: 14px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 6px 24px -4px rgba(16, 24, 40, 0.1),
        0px 0px 8px -4px rgba(16, 24, 40, 0.04);
}
