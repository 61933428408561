@import "../../css/helpers/colors.scss";

.projectCostContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.projectCost {
    color: $grey900;
    text-align: center;
    font-family: PlusJakartaSans;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -1.2px;
}

.uppercaseText {
    color: $grey600;
    text-align: center;
    font-family: PlusJakartaSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-transform: uppercase;
}
