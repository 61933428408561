@import "../../../../css/helpers/colors.scss";

.wrapper {
    overflow-y: auto;
    height: 100vh;
    margin-bottom: 80px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 7px;
}
