@import "../../../../css/helpers/colors.scss";
.blockWrap {
    padding: 0px 30px;
    margin-bottom: 50px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: $grey900;
    text-align: center;
}

.subtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey500;
    margin-left: 12px;
}

.experienceRow {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    .orange {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $warning500;
    }
}

.experiencePriceBlock {
    margin-top: 24px;
    margin-bottom: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.sliderLimit {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    display: inline-block;
    top: calc(-100% + 10px);
}

.sliderContainer {
    position: relative;
    margin: 0 auto 26px;
}

.sliderLimitLeft {
    left: 0;
}

.sliderLimitRight {
    right: 0;
}

.optionContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0 auto 24px;
    justify-content: center;
}

.option {
    display: inline-block;
    padding: 6px 12px;
    background: $grey25;
    border: 1px solid $grey200;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
    cursor: pointer;
}

.resourceCarouselWrapper {
    margin-bottom: 14px;
}

.buttonFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectedOption {
    background-color: $warning500;
    color: $white;
}
