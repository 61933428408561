@import "../../../css/helpers/colors.scss";

.modalSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey500;
}

.modalResource {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background: $warning50;
    border: 1px solid $warning200;
    border-radius: 8px;
}

.modalResources {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modalBtn {
    padding: 4px 16px !important;
    border-radius: 4px !important;
    font-family: "Helvetica" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.secondaryBtn {
    background-color: #fff !important;
}
