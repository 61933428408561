@import "../../../../../css/helpers/colors.scss";

.wrapper {
    padding: 45px 56px;
}

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $grey600;
}

.roundedSliderContainer {
    margin-top: 57px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: $grey900;
    margin-bottom: 16px;
}

.addResourceBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $grey800;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 22px;
    border: none;
    border-radius: 8px;
}

.resourceDropdownFlex {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    & > div {
        flex-grow: 1;
        flex-basis: calc(50% - 8px);
    }
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
}
