@import "../../../../../css/helpers/colors.scss";

.wrapper {
    padding: 45px 56px;
}

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $grey600;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.fromWrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.note {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey400;
    margin-bottom: 8px;
}

.backButton {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modalText {
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: $grey500;
}
