@import "../../../../../css/helpers/colors.scss";

.container {
    display: flex;
    align-items: center;
    padding: 14px 32px;
    border-bottom: 1px solid $grey200;
    background-color: $white;
    width: 100%;

    .prevbutton {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $grey600;
        cursor: pointer;
        background-color: transparent;
        border: none;
        margin-right: 16px;
    }
    .closeBtn {
        background-color: transparent;
        border-radius: 12px;
        border: 1px solid $grey300;
        padding: 10px;
        cursor: pointer;
    }
}

.resourceTabContainer {
    display: flex;
    flex-direction: column;
    // gap: 12px;
    width: 100%;

    .budgetContainer {
        background-color: $grey600;
        padding: 10px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 20px;
            font-weight: 700;
            color: $white;

            span {
                color: $grey200;
                font-size: 14px;
                font-weight: 400;
            }
        }

        small {
            font-size: 12px;
            font-weight: 400;
            color: $white;
        }
    }

    .accordion {
        box-shadow: none;
        border-bottom: 1px solid $grey200;
        &:before {
            background-color: transparent;
        }
    }

    .selectedAccordion {
        border-right: 4px solid $Primary;
        background: #fff6e7;
    }
}

.details {
    .links {
        display: flex;
        justify-content: space-between;

        span {
            cursor: pointer;
            color: $Primary;
            font-family: "PlusJakartaSans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .infoContainer {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .info {
            border-radius: 4px;
            border: 1px solid $grey200;
            background: $grey50;
            padding: 6px;
            margin-top: 10px;
            p {
                color: $grey600;
                font-family: "PlusJakartaSans";
                font-size: 12px;
                font-weight: 400;
            }

            small {
                color: $success500;
                font-family: "PlusJakartaSans";
                font-size: 12px;
                font-weight: 700;
                span {
                    color: $grey500;
                    font-family: "PlusJakartaSans";
                    font-size: 10px;
                    font-weight: 400;
                }
            }
        }
    }
}

.resourceTabWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    margin: 2px 0;

    .resourceTabLeft {
        display: flex;
        align-items: center;
        gap: 8px;

        .imageContainer {
            position: relative;

            .circle {
                position: absolute;
                top: -2px;
                right: -5px;
                width: 12px;
                height: 12px;
                border: 1px solid $grey400;
                border-radius: 50%;
                background-color: $white;

                &.selected {
                    border-color: $Primary;
                    &::before {
                        content: "";
                        display: block;
                        width: 8px; /* or whatever size you prefer for the inner circle */
                        height: 8px;
                        background-color: $Primary;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &.done {
                    border: none;
                    background-color: $success500;
                    .checkmark {
                        display: block;
                    }
                }

                .checkmark {
                    display: none;
                    position: absolute;
                    /* You may need to adjust the below properties to position the checkmark correctly inside the circle */
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 6px;
                    font-weight: bold;
                    color: $white;
                }
            }
            img {
                width: 24px;
                height: 24px;
            }
        }

        .resourceTitle {
            font-family: "PlusJakartaSans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            color: $grey900;
        }

        .resourceSubtitle {
            font-family: "PlusJakartaSans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: $grey500;
        }
    }

    .resourceTabRight {
        .resourceExperience {
            display: block;
            padding: 3px 12px;
            text-align: center;
            color: $grey500;
            font-family: "PlusJakartaSans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            background-color: $grey50;
            border: 1px solid $grey300;
            border-radius: 20px;
        }
    }
}
.addResource {
    border-radius: 12px;
    border: 1px dashed $grey200;
    background: $grey25;
    padding: 12px 24px;
    margin: 16px;
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $grey500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
}

@media only screen and (max-width: 767px) {
    .resourceTabContainer {
        .selectedAccordion {
            border-right: 4px solid $Primary;
            background: #fff6e7;
        }
    }
}
