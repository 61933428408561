@import "../../../../../css/helpers/colors.scss";

.wrapper {
    padding: 45px 56px;
}

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $grey600;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
}

.title {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-bottom: 48px;
}

.content {
    width: 80%;
    margin: 48px auto 80px;
}
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 32px;
}

.resultSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey500;
}

.resultBlue {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $blue600;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 80%;
    margin: 48px auto;
}

.card {
    cursor: pointer;
    flex-basis: calc(33% - 16px);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    background: $success25;
    border: 1px solid $success400;
    border-radius: 8px;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $grey900;
}

.cardBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #1f1f1f;
}

.cardGreen {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $success600;
}

.subtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.resultBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $grey900;
}

.flexSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalContent {
    max-width: 320px;
    padding-bottom: 16px;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey900;

    p {
        margin-bottom: 16px;
    }
}

.modalDiscountBox {
    padding: 6px 12px;

    background: rgba(250, 169, 26, 0.1);
    border-left: 4px solid #faa91a;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.orangeText {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $warning500;
    text-align: center;
    margin-bottom: 120px;
    cursor: pointer;
}

.modalDiscountContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.unstyledBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
