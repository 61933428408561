@import "../../../css/helpers/colors.scss";

.topup {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    width: 98px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 16px;
    background: $white;
    border: 1px solid $grey50;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
}

.topup_item {
    cursor: pointer;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey600;
}
