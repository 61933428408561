@import "../../css/helpers/colors.scss";

.selectedRole {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.center {
        justify-content: center;
        margin-top: 18px;
    }
}

.selectedRoleLeft {
    display: flex;
    align-items: center;
    gap: 8px;

    & button {
        border-radius: 12px;
        padding: 8px;
        border: none;
        background-color: $Primary;
        cursor: pointer;
    }

    .selectedRoleInfo {
        p {
            color: $grey800;
            font-family: "PlusJakartaSans";
            font-weight: 400;
        }
        small {
            color: $grey700;
            font-size: 14px;
            font-weight: 400;

            span {
                color: $grey400;
                font-size: 12px;
            }
        }
    }
}

.selectedRoleControls {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 36px;
    margin-left: 8px;

    .selectedRowCount {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: 30px;
        height: 100%;
        border: none;
        text-align: center;
        font-family: "PlusJakartaSans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        &:focus {
            outline: none;
        }
    }

    button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        background: $white;
        border: 1px solid $grey200;
        border-radius: 7px;
    }
}
