@import "../../css/helpers/colors.scss";

.resourceTabContainer {
    display: flex;
    flex-direction: column;
    // gap: 12px;
    width: 100%;

    .budgetContainer {
        background-color: $grey600;
        padding: 10px 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 20px;
            font-weight: 700;
            color: $white;

            span {
                color: $grey200;
                font-size: 14px;
                font-weight: 400;
            }
        }

        small {
            font-size: 12px;
            font-weight: 400;
            color: $white;
        }
    }

    .accordion {
        box-shadow: none;
        border-bottom: 1px solid $grey200;
        &:before {
            background-color: transparent;
        }
    }

    .selectedAccordion {
        border-right: 4px solid $Primary;
        background: #fff6e7;
    }
}

.editModal {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: baseline;
    margin-bottom: 20px;
    p {
        font-family: "PlusJakartaSans";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: $grey900;
    }
}

.customModalText {
    max-width: 312px;
}

.addResource {
    border-radius: 12px;
    border: 1px dashed $grey200;
    background: $grey25;
    padding: 12px 24px;
    margin: 16px;
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $grey500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.resourceTab {
    position: relative;
    padding: 0 8px 6px;
    width: fit-content;
    color: $grey600;
    cursor: pointer;

    &--selected {
        color: $warning500;
        border-bottom: 2px solid $warning500;
    }
}

.setDetailsBtn {
    display: inline-block;
    padding: 4px 12px;
    background: $grey25;
    cursor: pointer;
    border: 1px solid $grey200;
    border-radius: 6px;
    color: #000;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.resourceTabWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    margin: 2px 0;

    .resourceTabLeft {
        display: flex;
        align-items: center;
        gap: 8px;

        .imageContainer {
            position: relative;

            .circle {
                position: absolute;
                top: -2px;
                right: -5px;
                width: 12px;
                height: 12px;
                border: 1px solid $grey400;
                border-radius: 50%;
                background-color: $white;

                &.selected {
                    border-color: $Primary;
                    &::before {
                        content: "";
                        display: block;
                        width: 8px; /* or whatever size you prefer for the inner circle */
                        height: 8px;
                        background-color: $Primary;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &.done {
                    border: none;
                    background-color: $success500;
                    .checkmark {
                        display: block;
                    }
                }

                .checkmark {
                    display: none;
                    position: absolute;
                    /* You may need to adjust the below properties to position the checkmark correctly inside the circle */
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 6px;
                    font-weight: bold;
                    color: $white;
                }
            }
            img {
                width: 24px;
                height: 24px;
            }
        }

        .resourceTitle {
            font-family: "PlusJakartaSans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            color: $grey900;
        }

        .resourceSubtitle {
            font-family: "PlusJakartaSans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: $grey500;
        }
    }

    .resourceTabRight {
        .resourceExperience {
            display: block;
            padding: 3px 12px;
            text-align: center;
            color: $grey500;
            font-family: "PlusJakartaSans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            background-color: $grey50;
            border: 1px solid $grey300;
            border-radius: 20px;
        }
    }
}

.details {
    .links {
        display: flex;
        justify-content: space-between;

        span {
            cursor: pointer;
            color: $Primary;
            font-family: "PlusJakartaSans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .infoContainer {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .info {
            border-radius: 4px;
            border: 1px solid $grey200;
            background: $grey50;
            padding: 6px;
            margin-top: 10px;
            p {
                color: $grey600;
                font-family: "PlusJakartaSans";
                font-size: 12px;
                font-weight: 400;
            }

            small {
                color: $success500;
                font-family: "PlusJakartaSans";
                font-size: 12px;
                font-weight: 700;
                span {
                    color: $grey500;
                    font-family: "PlusJakartaSans";
                    font-size: 10px;
                    font-weight: 400;
                }
            }
        }
    }
}

span.delete {
    color: $error500 !important;
    cursor: pointer !important;
}

.nextIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 0;
    background: $grey50;
    border: 1px solid $grey200;
    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
}

.wrapper {
    position: relative;
}

.modalSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey500;
}

.modalResource {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background: $warning50;
    border: 1px solid $warning200;
    border-radius: 8px;
}

.modalResources {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modalBtn {
    padding: 4px 16px !important;
    border-radius: 4px !important;
    font-family: "Helvetica" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.secondaryBtn {
    background-color: #fff !important;
}

.modalButtons {
    display: flex;
    gap: 16px;
}

.deleteButton {
    background-color: $error500 !important;
}

.singleResourceMobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .singleResourceMobile {
        display: block;
    }
    .singleResourceHeader {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
    }
    .singleResourceHeaderTitle {
        text-align: center;
    }
    .singleResourceSubheader {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px 20px;
        align-items: center;
    }
    .singleResourceAmount {
        font-weight: bold;
        font-size: 20px;
        span {
            color: #667085;
            font-size: 16px;
        }
    }
    .singleResourceMiscButtons {
        gap: 10px;
        display: flex;
    }
}
