@import "../../css/helpers/colors.scss";

.modal {
    background: #ffffff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
        0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;

    @media (max-width: 1200px) {
        width: calc(100% - 32px);
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 1px solid $grey200;
}

.title {
    font-family: "PlusJakartaSans";
    // text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: $grey900;
    width: 100%;
}

.footer {
    padding: 20px 24px;
    border-top: 1px solid $grey200;
}

.content {
    padding: 20px 24px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $grey500;

    b {
        color: $grey700;
        font-weight: 700;
    }
}

.closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $grey200;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    padding: 5px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 9998;
}
