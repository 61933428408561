@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
    font-family: "PlusJakartaSans";
    src: local("PlusJakartaSans"),
        url("./fonts/PlusJakartaSans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "PlusJakartaSans", Inter, sans-serif;
}

body {
    overflow: hidden;
}

.wrapper {
    overflow: hidden;
}

.page-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    div[role="tabpanel"] {
        height: 100%;
    }
    @media only screen and (max-width: 1200px) {
        width: 100vw;
    }
}

.MuiSlider-thumb {
    &:first-of-type {
        display: none !important;
    }
}

.first-thumb {
    display: none !important;
}

#myLandbot {
    width: 40%;
    height: 100vh;
    position: absolute;
    right: 0;
    display: none !important;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        display: none;
    }
}

#botCloseIcon {
    display: none;
    position: fixed;
    top: 16px;
    right: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
}
.gantt .bar-label {
    font-weight: 400 !important;
}

.gantt .lower-text,
.gantt .bar-label.big {
    fill: #667085 !important;
    font-size: 14px !important;
}

.gantt .tick,
.gantt .tick.thick {
    stroke-width: 1 !important;
}

.gantt .grid-header {
    stroke-width: 0 !important;
}

.gantt .grid-row:nth-child(even) {
    fill: #fff !important;
}
.gantt .row-line {
    stroke: #fff !important;
}
