@import "../../../../../css/helpers/colors.scss";

.header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
}

.titleOpacity {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey900;
    display: flex;
    align-items: center;
    gap: 16px;
}

.fromWrapper {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.note {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey400;
    margin-bottom: 8px;
}

.btnContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 16px;
}

.unstyledBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.backButton {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modalText {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: $grey500;
}
