@import "../../css/helpers/colors.scss";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;
    border-bottom: 1px solid $grey200;
    background-color: $white;
    z-index: 999;
    width: 100%;

    .prevbutton {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $grey600;
        cursor: pointer;
        background-color: transparent;
        border: none;
    }
    .closeBtn {
        background-color: transparent;
        border-radius: 12px;
        border: 1px solid $grey300;
        padding: 10px;
        cursor: pointer;
    }
    .mobileTitle {
        font-size: 16px;
        color: var(--Primary, #faa91a);
        font-weight: 700;
    }
}

.budgetContainer {
    background-color: $grey600;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 20px;
        font-weight: 700;
        color: $white;

        span {
            color: $grey200;
            font-size: 14px;
            font-weight: 400;
        }
    }

    small {
        font-size: 12px;
        font-weight: 400;
        color: $white;
    }
}

.header {
    padding: 22px 16px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.prevbutton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.titleOpacity {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey900;
}

.roundedSliderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.discountBanner {
    flex-grow: 1;
    border-left: 4px solid $success600;
    background-color: $success50;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey700;
}

.greenBold {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $success600;
}

.discountBannerOpacity {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}
