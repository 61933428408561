@import "../../../../css/helpers/colors.scss";

.logo {
    margin-bottom: 140px;
}

.wrapper {
    display: flex;
    min-height: 100%;
    padding-top: 78px;
}

.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    text-align: center;
}

.header {
    margin-bottom: 67px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.logoFlex {
    display: flex;
    align-items: center;
    gap: 16px;
}

.subtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey500;
    margin-left: 12px;
}

.experienceRow {
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    .orange {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $warning500;
    }
}

.experiencePriceBlock {
    margin-top: 48px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.sliderLimit {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    display: inline-block;
    top: calc(-100% + 10px);
}

.sliderContainer {
    position: relative;
    width: 60%;
    margin: 0 auto 32px;
}

.sliderLimitLeft {
    left: 0;
}

.sliderLimitRight {
    right: 0;
}

.optionContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 64px;
    width: fit-content;
    margin: 0 auto;
}

.option {
    display: inline-block;
    padding: 6px 24px;
    background: $white;
    border: 1px solid $grey200;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
    cursor: pointer;
}

.resourceCarouselWrapper {
    width: 25vw;
    border-right: 1px solid $grey200;
    min-width: 275px;
}

.mainConatiner {
    flex: 1;
    padding: 32px;
    position: relative;
    display: flex;
    flex-direction: column;

    .innerConatiner {
        display: flex;
        flex: 1;
        justify-content: space-around;
        .leftButton {
            button {
                visibility: hidden;
            }
        }
        .rightButton,
        .leftButton {
            align-self: center;
        }
    }
}

.activeOption {
    background-color: $warning500;
    color: $white;
}

.nextIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 1px solid $grey300;
    border-radius: 100px;
    padding: 10px;
    cursor: pointer;
}

.doneButton {
    display: flex;
    justify-content: center;
    border-top: 1px solid $grey200;
    position: relative;
    width: 104.6%;
    bottom: -32px;
    left: -32px;
    padding: 20px 32px;
}

.flexSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modalBtns {
    gap: 16px;
    padding: 16px 0;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding-top: 50px;
    }
    .resourceCarouselWrapper {
        min-width: 100%;
    }
    /* .mainConatiner {
        display: none;
    } */
}
