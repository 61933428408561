@import "../../../../../../css/helpers/colors.scss";

.ResourseCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid $grey200;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 220px;
    padding: 16px;

    img {
        width: 56px;
        height: 56px;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        color: $grey900;
        margin-top: 12px;
    }
    .count {
        font-size: 12px;
        font-weight: 400;
        color: $grey500;
    }
    .priceDiv {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .price {
            color: $grey800;
            font-size: 18px;
            font-weight: 700;
            margin-top: 8px;

            span {
                color: $grey400;
                font-size: 14px;
                font-weight: 400;
            }
        }

        small {
            color: $Primary;
            font-size: 12px;
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: 767px) {
    .ResourseCard {
        width: 100%;
        margin-bottom: 20px;
    }
}
