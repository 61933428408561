@import "../../css/helpers/colors.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 12px;
    width: fit-content;
    padding: 36px 26px;
    cursor: pointer;
    min-height: 260px;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 16px;
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: $grey500;
}

@media only screen and (max-width: 767px) {
    .title {
        font-size: 24px;
        font-weight: 700 !important;
    }
    .subtitle {
        font-size: 14px;
    }
    .wrapper {
        width: 100%;
    }
}
