@import "../../../../css/helpers/colors.scss";

.blockWrap {
    padding: 0px 30px;
    margin-bottom: 50px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: $grey900;
    text-align: center;
}

.buttonFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey500;
    margin-left: 12px;
}

.card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 20px 20px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey800;
    background: $grey25;
    border: 1px solid $grey200;
    border-radius: 8px;
}

.selectedCard {
    background: $warning25;
    border: 1px solid $warning400;
    color: $warning500;
}

.cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 16px 0 24px;
}

.resourceCarouselWrapper {
    margin-bottom: 14px;
}

.subtitle {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $grey900;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto 32px;
}

.experiencePriceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
}
