@import "../../../../../css/helpers/colors.scss";

.wrapper {
    padding: 45px 56px;
    min-height: 100%;
    padding-top: 100px;

    .innerContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        .leftSection {
            padding: 0 4vw;

            .title {
                color: $grey900;
                text-align: center;
                font-family: "PlusJakartaSans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }
        }
        .rightSection {
            position: relative;
            .tabsContainer {
                display: flex;
                margin-bottom: 24px;

                .tab {
                    width: 140px;
                    color: $grey500;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 10px 0;
                    border-bottom: 2px solid $grey100;
                    cursor: pointer;
                    text-align: center;

                    &.selected {
                        color: $Primary;
                        border-color: $Primary;
                    }
                }
            }

            .tabContent {
                visibility: hidden;
                width: 710px;
                position: absolute;
                top: 0;
                &.selected {
                    visibility: visible;
                    position: relative;
                }
                .resources {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;
                    padding-bottom: 160px;
                }

                .timeline {
                    & svg {
                        cursor: default;
                        pointer-events: none;
                    }
                }

                .discount {
                    .title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 16px;
                        p {
                            color: $grey900;
                            font-size: 16px;
                            font-weight: 400;
                        }
                        h5 {
                            color: $blue600;
                            font-size: 24px;
                            font-weight: 700;

                            span {
                                color: $grey500;
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }

                    .discountCards {
                        display: flex;
                        gap: 24px;
                        flex-wrap: wrap;
                        .card {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 8px;
                            min-width: 220px;
                            border-radius: 12px;
                            border: 1px solid $grey200;
                            padding: 16px;
                            /* Shadow/xs */
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                            .title {
                                color: $grey900;
                                font-family: "PlusJakartaSans";
                                font-size: 16px;
                                font-weight: 700;
                                margin-bottom: 0;
                            }

                            small {
                                color: $grey500;
                                font-family: "PlusJakartaSans";
                                font-size: 14px;
                                font-weight: 400;
                            }

                            .bottom {
                                display: flex;
                                justify-content: space-between;
                                align-items: baseline;

                                .green {
                                    color: $success600;
                                    font-size: 18px;
                                    font-weight: 700;
                                    span {
                                        color: $grey400;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }

                                .saveMore {
                                    color: $Primary;
                                    font-family: "PlusJakartaSans";
                                    font-size: 12px;
                                    font-weight: 400;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btnContainer {
        position: fixed;
        width: 100%;
        bottom: 140px;
        display: flex;
        justify-content: center;
        gap: 12px;

        button {
            padding: 12px 24px !important;
            font-size: 18px;
            font-weight: 700;
            box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04),
                0px 20px 24px -4px rgba(16, 24, 40, 0.1);
        }
    }
}

.fromWrapper {
    input {
        border: none;
        background-color: $grey50;
        margin-bottom: 16px;
    }
}

.textarea {
    margin-top: 16px;
    div {
        background-color: $grey50;
        border: none;
    }
    textarea {
        border-radius: 12px;
    }
}
.note {
    margin-top: 12px;
}

.footerBtns {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    button {
        margin: 0;
    }
}

.customModal {
    img {
        margin-bottom: 24px;
    }
    h2 {
        color: $grey900;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 24px;
    }
}

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modalContent {
    min-width: 400px;
}

.titleOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $grey600;
    display: flex;
    align-items: center;
    gap: 16px;
}

.topCardContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 70px;
    margin: 32px 0;
}

.topCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.topCardBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $grey900;
}

.topCardBoldGreen {
    color: $success700;
}

.topCardSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: $grey600;
}

.subtitle {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $grey600;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
}

.table {
    width: 100%;
    border-spacing: 0 20px;
    margin-bottom: 64px;
}

.thead th {
    text-align: left;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey600;
}

.tbody td {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.tableBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.tableGrey {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $grey800;
    padding: 0 8px;
}

.tableGreyMiddle {
    border-right: 1px solid $grey300;
    border-left: 1px solid $grey300;
}

.alignCenter {
    display: flex;
    align-items: center;
    gap: 5px;
}

.resourceTimelineContainer {
    margin-bottom: 12px;
}

.buttonsBlock {
    display: flex;
    margin-top: 64px;
}

.button {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding: 14px 20px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    cursor: pointer;

    &:nth-child(1) {
        margin-left: 0;
        background: $orange50;
        border-color: $orange200;
        color: $orange400;
    }

    &:nth-child(2) {
        background: $bluelight50;
        border-color: $bluelight200;
        color: $bluelight500;
    }

    &:last-child {
        background: $purple50;
        border-color: $purple200;
        color: $purple500;
        margin-left: auto;
    }

    & > span {
        display: block;
        margin-left: 8px;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
}

.footerItem {
    display: flex;
    align-items: center;

    & > span {
        display: block;
        margin-left: 8px;
        font-family: "PlusJakartaSans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #474747;
    }
}

.backButton {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.unstyledBtn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modalOr {
    margin: 24px 0;
    height: 1px;
    background-color: $grey100;
}

.sendMail {
    display: flex;
    gap: 12px;
    align-items: flex-end;
}

.copied {
    color: $success500 !important;
}
.btnContainerMobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding: 20px 10px;
        padding-top: 50px;

        .innerContainer {
            flex-direction: column;
            .leftSection {
                padding: 0;
                margin-bottom: 24px;
                .title {
                    text-align: center;
                    margin-top: 30px;
                }
            }

            .rightSection {
                .tabContent {
                    width: 100%;
                    .resources {
                        gap: 0;
                        flex-direction: column;
                        padding-bottom: 60px;
                    }

                    .discount {
                        .titleOverall {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 16px;
                            align-items: baseline;
                            p {
                                color: $grey900;
                                font-size: 16px;
                                font-weight: 400;
                            }
                            h5 {
                                color: $blue600;
                                font-size: 24px;
                                font-weight: 700;

                                span {
                                    color: $grey500;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .discountCards {
                            .card {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .btnContainerMobile {
            position: fixed;
            width: 100%;
            bottom: 10px;
            display: flex;
            justify-content: space-between;
            padding: 20px 20px 0 0px;
            button {
                padding: 12px 24px !important;
                font-size: 18px;
                font-weight: 700;
                box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04),
                    0px 20px 24px -4px rgba(16, 24, 40, 0.1);
            }
        }
        .btnContainerDesktop {
            display: none;
        }
    }

    .modalContent {
        min-width: 100%;
    }
}
