@import "../../../../../css/helpers/colors.scss";

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orangeTitle {
    font-family: Helvetica;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $warning500;
    text-align: center;
    margin-bottom: 16px;
}

.note {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: $grey500;
}

.centeredText {
    margin: 0 auto;
    margin-top: 40%;
    padding: 16px;
}

.purpleCont {
    cursor: pointer;
    background: $purple50;
    border: 1px solid $purple200;
    border-radius: 12px;
    padding: 14px 16px;
    margin-top: 120px;
}

.purpleText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: $purple500;
    margin-bottom: 4px;
}

.purpleContSubtext {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey600;
}
