@import "../../../../../css/helpers/colors.scss";

.wrapper {
    padding: 45px 56px;
    .innerContainer {
        display: flex;
        flex: 1;
        justify-content: space-around;
        .rightButton,
        .leftButton {
            align-self: center;
        }
        .nextIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            border: 1px solid $grey300;
            border-radius: 100px;
            padding: 10px;
            cursor: pointer;
        }
    }
}

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $grey600;
}

.roundedSliderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 32px;
    border-top: 1px solid $grey200;
}

.discountBanner {
    flex-grow: 1;
    border-left: 4px solid $success600;
    background-color: $success50;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey700;
}

.greenBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $success600;
}

.discountBannerOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.title {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-bottom: 48px;
    text-align: center;
}

.content {
    width: 80%;
    margin: 48px auto 80px;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 32px;
    margin-bottom: 32px;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey900;
}

.resultSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey500;
}

.resultGreen {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $success600;
}

.sliderContainer {
    margin-bottom: 32px;
}

.setPercentBtn {
    padding: 6px 24px;
    background: $grey25;
    border: 1px solid $grey200;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.setPercentBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.selectedOption {
    background-color: $warning400;
    color: $white;
}
