@import "../../css/helpers/colors.scss";

.dropdownWrapper {
    color: white;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.footer {
    padding: 12px 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    text-transform: uppercase;
}

.footerOpacity {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.04em;
    color: white;
    opacity: 0.7;
}

.budgetUsedBox {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
}

.footerRight {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.unstyledBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.resourceRow {
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rowOpacity {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.04em;
    opacity: 0.7;
}

.rowTotal {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    gap: 24px;
}

.rotated {
    transform: rotate(180deg);
}

.backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
}
