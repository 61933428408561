@import "../../../../css/helpers/colors.scss";

/* .wrapper {
    padding-top: 64px;
} */

/* .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 16px 14px;
    background-color: white;
} */

.titleCloseBtn {
    display: flex;
    align-items: center;
    gap: 16px;
}

.title {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $grey900;
}

.closeBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.doneBtn {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $success600;
    background-color: transparent;
    border: none;
}

.botMobileContainer {
    position: fixed;
    right: 76px;
    bottom: 76px;
}

.prevbutton {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    color: $grey600;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.mobileTitle {
    font-size: 16px;
    color: var(--Primary, #faa91a);
    font-weight: 700;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;
}

.budgetContainer {
    background-color: $grey600;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 20px;
        font-weight: 700;
        color: $white;

        span {
            color: $grey200;
            font-size: 14px;
            font-weight: 400;
        }
    }

    small {
        font-size: 12px;
        font-weight: 400;
        color: $white;
    }
}

.singleResourceMobile {
    display: block;
}
.singleResourceHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
}
.singleResourceHeaderTitle {
    text-align: center;
}

.doneButtonMobile {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 10px 20px;
}
.usersButton {
    padding: 5px 10px;
    border-radius: 12px;
    border: 1px solid $Primary;
    background: $white;
    position: relative;
    img {
        width: 18px;
        height: 18px;
    }
    .number {
        position: absolute;
        right: -14px;
        top: -14px;
        display: block;
        font-size: 12px;
        color: $white;
        background-color: $Primary;
        border-radius: 50%;
        padding: 5px;
        width: 25px;
        height: 25px;
    }
}
@media only screen and (max-width: 767px) {
    .flexSB {
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }
}
