@import "../../css/helpers/colors.scss";

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;
    border-bottom: 1px solid $grey200;
    position: fixed;
    top: 0;
    background-color: $white;
    z-index: 999;
    width: 100%;

    .prevbutton {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $grey600;
        cursor: pointer;
        background-color: transparent;
        border: none;
    }
    .closeBtn {
        background-color: transparent;
        border-radius: 12px;
        border: 1px solid $grey300;
        padding: 10px;
        cursor: pointer;
    }
}

.modalContent {
    min-width: 400px;

    .modalOr {
        margin: 8px 0;
        text-align: center;
        color: $grey700;
        font-family: "PlusJakartaSans";
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }
    .unstyledBtn {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .questionContainer {
        .title {
            color: $grey900;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 12px;
        }
        .inputContainer {
            display: flex;
            align-items: center;
            margin-bottom: 32px;
            width: fit-content;
            border-radius: 12px;
            background-color: $grey50;
            padding: 14px 12px;

            & input {
                margin-left: 12px;
            }
        }
    }
}
.footer {
    display: flex;
    justify-content: space-between;
    button {
        font-size: 12px;
        padding: 11px 14px;
    }
}

.hidden {
    visibility: hidden;
}

.footerBtns {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    button {
        margin: 0;
    }
}

.sendMail {
    display: flex;
    gap: 12px;
    align-items: flex-end;
}

.copied {
    color: $success500 !important;
}

.mobileTitle {
    font-size: 16px;
    color: var(--Primary, #faa91a);
    font-weight: 700;
}
