@import "../../css/helpers/colors.scss";

.inputContainer {
    position: relative;
}

.input {
    padding: 10px 14px;
    width: 100%;
    border: 1px solid $grey300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey900;

    &:focus {
        outline: none;
        border: 1px solid $primary300;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px $primary100;
    }

    &::placeholder {
        color: $grey500;
    }
}

.hasError {
    border: 1px solid $error300 !important;

    &:focus,
    &:focus-within {
        outline: none !important;
        border: 1px solid $error300 !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px $error100 !important;
    }
}

.inputError {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $error500;
    margin-top: 6px;
    position: absolute;
    bottom: -24px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $grey900;
    margin-bottom: 6px;
    display: block;
    text-align: left;
}

.inputWrapper {
    position: relative;
}

.postfix {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}
