@import "../../../../../css/helpers/colors.scss";

.wrapper {
    overflow-y: auto;
    height: 100vh;
    margin-bottom: 80px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 7px;
}

.title {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    color: $grey900;
    margin-bottom: 16px;
}

.content {
    padding: 0 16px;
    margin: 24px auto;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 32px;
    margin-bottom: 32px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey900;
}

.resultSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey500;
}

.resultGreen {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $success600;
}

.sliderContainer {
    margin-bottom: 32px;
}

.setPercentBtn {
    flex: 1;
    padding: 6px;
    background: $grey25;
    border: 1px solid $grey200;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.setPercentBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.botMobileContainer {
    position: fixed;
    right: 76px;
    bottom: 152px;
}

.selectedOption {
    background-color: $warning400;
    color: $white;
}
