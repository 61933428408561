@import "../../../../../css/helpers/colors.scss";

.wrapper {
    overflow-y: auto;
    height: 100vh;
    margin-bottom: 80px;
}

.header {
    padding: 22px 16px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.prevbutton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.titleOpacity {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey900;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 7px;
}

.title {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-bottom: 48px;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.resultSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey500;
}

.resultBlue {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $blue600;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 24px auto;
}

.card {
    cursor: pointer;
    flex-basis: calc(50% - 6px);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    background: $success25;
    border: 1px solid $success400;
    border-radius: 8px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $grey900;
}

.cardBold {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #1f1f1f;
}

.cardGreen {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $success600;
}

.subtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.resultBold {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $grey900;
}

.flexSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalContent {
    padding-bottom: 16px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey900;

    p {
        margin-bottom: 16px;
    }
}

.modalDiscountBox {
    padding: 6px 12px;
    background: rgba(250, 169, 26, 0.1);
    border-left: 4px solid #faa91a;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.orangeText {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $warning500;
    text-align: center;
    margin-bottom: 24px;
    cursor: pointer;
}

.modalDiscountContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.unstyledBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.content {
    padding: 0 16px;
}

.botMobileContainer {
    position: fixed;
    right: 76px;
    bottom: 152px;
}
