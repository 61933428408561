@import "../../../css/helpers/colors.scss";

.wrapper {
    background: #ffffff;
    border: 1px solid $grey50;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
    color: $grey600;
    font-family: "PlusJakartaSans";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    position: absolute;
    z-index: 11;
}

.item {
    padding: 8px;
    padding-left: 16px;
    padding-right: 30px;
    cursor: pointer;
}
