@import "../../../../../../css/helpers/colors.scss";

.doughnut {
    width: 240px;
    height: 240px;
    margin: 40px auto;
    position: relative;

    .centerText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        pointer-events: none;
        z-index: -1;
        p {
            font-size: 30px;
            font-weight: 700;
            color: #000;

            span {
                font-size: 16px;
                color: $grey500;
                display: block;
            }
        }
    }
}

.labelsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    width: 330px;
    margin: auto;

    .label {
        display: flex;
        gap: 8px;

        span {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 4px;
        }

        small {
            color: $grey700;
            font-family: "PlusJakartaSans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .labelsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        width: auto;
        .label {
            flex: 0 0 50%;
            box-sizing: border-box;
            margin-bottom: 10px;

            small {
                font-size: 12px;
            }
        }
    }
}
