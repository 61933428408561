@import "../../../../css/helpers/colors.scss";

.logo {
    margin-bottom: 140px;
}

.wrapper {
    display: flex;
    min-height: 100%;
    padding-top: 78px;
}

.mainConatiner {
    flex: 1;
    padding: 32px;
    position: relative;
    display: flex;
    flex-direction: column;

    .innerConatiner {
        display: flex;
        flex: 1;
        justify-content: space-around;
        .rightButton {
            button {
                visibility: hidden;
            }
        }
        .rightButton,
        .leftButton {
            align-self: center;

            .nextIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                background: $white;
                border: 1px solid $grey300;
                border-radius: 100px;
                padding: 10px;
                cursor: pointer;
            }
        }
    }
}

.doneButton {
    display: flex;
    justify-content: center;
    border-top: 1px solid $grey200;
    position: relative;
    width: 104.6%;
    bottom: -32px;
    left: -32px;
    padding: 20px 32px;
}

.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    text-align: center;
}

.header {
    margin-bottom: 67px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.logoFlex {
    display: flex;
    align-items: center;
    gap: 16px;
}

.buttonFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 11px 64px;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey800;
    background: $grey25;
    border: 1px solid $grey200;
    border-radius: 8px;
    cursor: pointer;
}

.selectedCard {
    background: $warning25;
    border: 1px solid $warning400;
}

.cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 48px 0;
}

.subtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey500;
    margin-left: 12px;
}

.resourceCarouselWrapper {
    width: 25vw;
    border-right: 1px solid $grey200;
    min-width: 275px;
}

.subtitle {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey900;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: fit-content;
    margin: 0 auto 32px;
}

.experiencePriceBlock {
    margin-bottom: 68px;
}

.flexSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modalBtns {
    gap: 16px;
    padding: 16px 0;
}
