@import "../../../../../css/helpers/colors.scss";

.logoContainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
// .logo {
//     width: ;
// }

.cardContainer {
    display: flex;
    align-items: center;
    gap: 46px;
    flex: 1;
    // margin-bottom: 83px;

    & > div {
        flex-grow: 1;
    }
}

.blankCard {
    background: $white;
    border: 1px solid $warning100;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: $grey900;
    padding: 16px 32px;
    width: 40%;
    position: absolute;
    right: 400px;
}

.wrapper {
    // background: url("../../../../../css/icons/robotPic.png") calc(100% - 100px)
    //         calc(100% - 55px),
    //     url("../../../../../css/icons/homePageBgLine.png") calc(100% + 20px)
    //         100%;
    background-repeat: no-repeat;
    height: 100vh;
    padding: 0px 244px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $grey100;
}

.modalContent {
    min-width: 400px;
}

.unstyledBtn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modalOr {
    margin: 8px 0;
    text-align: center;
    color: $grey700;
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding: 20px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
    }
    .logoContainer {
        margin-bottom: 50px;
    }
}
