@import "../../css/helpers/colors.scss";

.circle {
    flex-shrink: 0;
    position: relative;
    width: 300px;
    height: 150px;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
        width: 220px;
        height: 110px;
    }
}

.innerCircle {
    width: 260px;
    height: 130px;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1200px) {
        width: 188px;
        height: 94px;
        left: 16px;
    }
}

.middleCircle {
    width: 280px;
    height: 140px;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;
    position: absolute;
    left: 10px;
    bottom: 0;

    @media (max-width: 1200px) {
        width: 204px;
        height: 102px;
    }
}

.leftRoundCorner {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: $warning400;
    left: 0;
    bottom: -10px;
    @media (max-width: 1200px) {
        width: 16px;
        height: 16px;
    }
}

.rightRoundCorner {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #e5eafc;
    right: 0;
    bottom: -10px;
    @media (max-width: 1200px) {
        width: 16px;
        height: 16px;
    }
}

.mark {
    position: absolute;

    &::after {
        content: "";
        position: absolute;
        background-color: $warning400;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 10px solid white;
        box-shadow: 0px 11.4577px 11.4577px rgba(13, 10, 44, 0.08);
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 1200px) {
            width: 16px;
            height: 16px;
            border-width: 8px;
        }
    }
}

.percent {
    margin-top: 40px;
    font-family: "PlusJakartaSans";
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: center;

    color: $grey900;

    @media (max-width: 1200px) {
        margin-top: 20px;
    }
}

.bottomRow {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
}

.orange {
    font-family: "PlusJakartaSans";
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: $warning500;
}

.grey {
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: $grey600;
}
