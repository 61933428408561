@import "../../../../../css/helpers/colors.scss";

.userCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
    max-height: 75.2px;

    .userCardLeft {
        display: flex;
        align-items: center;
        gap: 12px;

        .userButtons {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                border: none;
                width: 16px;
                height: 16px;
                cursor: pointer;
                background-color: transparent;
                img {
                    width: 100%;
                    height: 100%;
                }
                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 50%;
                }
            }

            span {
                font-size: 8px;
                font-weight: 500;
                color: $grey500;
            }
        }

        img {
            width: 32px;
            height: 32px;
        }
        .userCard {
            flex: 1;
        }
        .userCardInfo {
            margin-left: 12px;
            p {
                color: $grey800;
                font-family: "PlusJakartaSans";
                font-weight: 700;
                text-align: left;
                span {
                    color: $grey400;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            small {
                color: $grey500;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}

.perResourceText {
    .mobileText {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .perResourceText {
        .mobileText {
            display: block;
        }
        .desktopText {
            display: none;
        }
    }
}
