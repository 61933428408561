@import "../../css/helpers/colors.scss";

.closeBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dropdownWrapper {
    position: relative;
}

.dropdownHeader {
    padding: 8px 12px;
    background-color: $orange50;
    border-left: 4px solid $orange400;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.setDetailsBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
}

.dropdownHeaderLeft {
    display: flex;
    flex-direction: column;
}

.dropdownTitle {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $grey800;
}

.dropdownHeaderFlexItemLeft {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdownSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.dropdownHeaderFlexItemRight {
    display: flex;
    align-items: center;
    gap: 4px;
}

.open {
    transform: rotate(180deg);
}

.content {
    padding: 24px 0;
}

.singleResourceMobile {
    display: block;
}
.singleResourceHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
}
.singleResourceHeaderTitle {
    text-align: center;
}
.singleResourceSubheader {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 20px;
    align-items: center;
}
.singleResourceAmount {
    font-weight: bold;
    font-size: 20px;
    span {
        color: #667085;
        font-size: 16px;
    }
}
.singleResourceMiscButtons {
    gap: 10px;
    display: flex;

    button {
        border: 0;
        background-color: transparent;
    }
}
.modalButtons {
    display: flex;
    gap: 10px;
}
.selectedPickerItem {
    display: flex;
    padding: 8px 18px 8px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 9999px;
    border: 1px solid var(--gray-100, #f2f4f7);
    background: var(--White, #fff);
    justify-content: space-between;
    .pickerContainerLeft {
        display: flex;
        gap: 12px;
        align-items: center;

        .pickerText {
            h4 {
                color: #1d2939;
                font-size: 14px;
                font-style: normal;
                font-weight: 800;
            }
            .pickerAddons {
                span {
                    color: #667085;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-right: 10px;
                    font-size: 14px;
                }
                span.green {
                    color: #00b87c;
                    margin-right: 0;
                }
                small {
                    font-size: 10px;
                    font-size: 10px;
                }
            }
        }
    }
}
.customDropDownPicker {
    padding: 0 15px;
    margin-bottom: 30px;
    ul {
        margin-top: 10px;
        border: 1px solid var(--gray-100, #f2f4f7);
        background: var(--White, #fff);
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05),
            0px 12px 16px -4px rgba(16, 24, 40, 0.1);
        border-radius: 12px;
        li {
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            color: #667085;
            list-style: none;
            cursor: pointer;
            &:hover {
                background-color: #f5f5f5;
            }
            .selectedPickerItem {
                position: relative;
                justify-content: flex-start;
                border: 0;
                border-radius: 0;
                border-bottom: 1px solid #f2f4f7;
                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    z-index: 1;
                }
            }
            &:first-child {
                .selectedPickerItem {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                }
            }
            &:last-child {
                .selectedPickerItem {
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
}
