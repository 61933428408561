@import "../../../../../css/helpers/colors.scss";

.wrapper {
    padding: 45px 56px 0;
    .innerContainer {
        display: flex;
        flex: 1;
        justify-content: space-around;
        .leftButton {
            button {
                visibility: hidden;
            }
        }
        .rightButton,
        .leftButton {
            align-self: center;
        }
        .nextIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            border: 1px solid $grey300;
            border-radius: 100px;
            padding: 10px;
            cursor: pointer;
        }
    }
}

.header {
    margin-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $grey600;
}

.roundedSliderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 32px;
    border-top: 1px solid $grey200;
}

.discountBanner {
    flex-grow: 1;
    border-left: 4px solid $success600;
    background-color: $success50;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey700;
}

.greenBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $success600;
}

.discountBannerOpacity {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.title {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    margin-bottom: 48px;
    text-align: center;
}

.content {
    width: 80%;
    margin: 48px auto 80px;
}

.card {
    cursor: pointer;
    border: 1px solid;
    border-radius: 8px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $grey900;
}

.cardYes {
    background: $success25;
    border-color: $success200;
}

.cardNo {
    background: $grey25;
    border-color: $grey200;
}

.cardFlex {
    display: flex;
    justify-content: center;
    gap: 24px;
}

.greenSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $success500;
}

.redSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $error600;
}

.yellowSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $warning400;
}

.greySubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey400;
}

.subtitle {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $grey900;
    text-align: center;
    margin: 48px 0 16px;
}

.cardSM {
    padding: 8px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 32px;
}

.resultSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $grey500;
}

.resultGreen {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $success600;
}

.selectedCard {
    border: 1px solid #faa91a;
    background: #fff3dd;
}
