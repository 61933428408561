@import "../../../css/helpers/colors.scss";

.multiStep {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
}

.step {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 100%;

    p {
        color: $grey600;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    &.active {
        p {
            color: $Primary;
            font-weight: 700;
        }
        .circle {
            border-color: $Primary;
        }
        &::before {
            background-color: $Primary;
        }
    }

    &.completed {
        p {
            color: $grey400;
        }

        .circle {
            border-color: $Primary;
            background-color: #feca6e;
        }
        &::before,
        &::after {
            background-color: $Primary;
        }
    }
}

.circle {
    background-color: $white;
    border: 1px solid $grey200;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.step::before,
.step::after {
    content: "";
    position: absolute;
    background-color: $grey200;
    height: 2px;
    width: 50%;
    bottom: 16%;
    transform: translateY(-50%);
}

.step::before {
    left: -50%;
}

.step::after {
    right: -50%;
}

.step:first-child::before,
.step:last-child::after {
    content: none;
}
