@import "../../../../../css/helpers/colors.scss";

.logo {
    margin-bottom: 140px;
}

.wrapper {
    padding: 7vw 0 4.1vw 0;
    min-height: 100%;
}
.customSelectIcon {
    color: red;
    right: 10px;
}
.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey700;
    margin-bottom: 32px;
    text-align: center;
}

.header {
    margin-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchContainer {
    position: relative;
    margin: 0 auto 24px;
    width: 840px;
    display: flex;
    gap: 16px;
}

.search {
    flex: 2;
    width: 100%;
    padding: 8px 12px 8px 40px;
    border-radius: 12px;
    border: 1.5px solid $grey100;
    background: $grey25;
    height: 52px;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-family: "PlusJakartaSans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey400;
    }
}

.inputContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 12px;
    background-color: $grey50;
    /* padding: 14px 12px; */
    width: 280px;
    border: 1.5px solid $grey100;

    div {
        margin: auto !important;
        width: 100%;
        background-color: $grey50 !important;
    }
}

.searchIcon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.subtitle {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
    margin-bottom: 12px;
}

.roleContainer {
    min-width: 220px;
    max-width: 220px;
    min-height: 280px;
    background: $white;
    border: 1px solid $grey200;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: calc(25% - 18px);
    padding: 16px;
    gap: 16px;

    opacity: 0.8;
    transition: background-color 0.5s ease, opacity 0.5s ease;

    .vector {
        height: 56px;
        width: 56px;
        img {
            width: 100%;
        }
    }

    h6 {
        font-size: 18px;
        font-weight: 700;
        color: $grey900;
        line-height: 28px;
    }

    p {
        color: $grey700;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        span {
            color: $grey400;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
    }
    .selectButton {
        width: 100%;
        padding: 8px 24px;
        border-radius: 12px;
        background: $grey100;
        border: none;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
    }

    &.selected {
        opacity: 1;
        border: 1px solid $Primary;
        background: linear-gradient(
                0deg,
                rgba(250, 169, 26, 0.1) 0%,
                rgba(250, 169, 26, 0.1) 100%
            ),
            #fff;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05),
            0px 12px 16px -4px rgba(16, 24, 40, 0.1);
    }
}

.skeleton {
    gap: 0 !important;
    & .placeholder {
        width: 56px;
        height: 56px;
        background: linear-gradient(267deg, #d9d9d9 -144.71%, #fff 161.95%);
        border-radius: 50%;
        margin-bottom: 12px;
    }

    & .longLine {
        height: 22px;
        width: 100%;
        background: linear-gradient(267deg, #d9d9d9 -144.71%, #fff 161.95%);
        margin-bottom: 10px;
        border-radius: 999px;
    }
    & .line {
        width: 136px;
        height: 18px;
        background: linear-gradient(267deg, #d9d9d9 -144.71%, #fff 161.95%);
        margin-bottom: 20px;
        border-radius: 999px;
    }
}

.roleFlex {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 952px;
    margin: 0 auto;
    margin-bottom: 48px;
}

.selectedRolesContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
}

.buttonContainer {
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04),
            0px 20px 24px -4px rgba(16, 24, 40, 0.1);
        cursor: pointer;
    }

    .confirmButton {
        padding: 12px 24px;
        font-size: 18px;
        margin-right: 16px;
    }
    .usersButton {
        padding: 14px 20px;
        border-radius: 12px;
        border: 1px solid $Primary;
        background: $white;
        position: relative;
        image {
            width: 24px;
            height: 24px;
        }
        .number {
            position: absolute;
            right: -14px;
            top: -14px;
            display: block;
            font-size: 12px;
            color: $white;
            background-color: $Primary;
            border-radius: 50%;
            padding: 10px;
            width: 35px;
            height: 35px;
        }
    }
}

.drawerTitle {
    color: $grey800;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.drawerTotal {
    color: $grey500;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    span {
        color: $grey700;
        font-size: 14px;
        font-weight: 700;
    }
}

.selectedRoleConatiner {
    margin: 24px 0;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding: 80px 0;
        min-height: 100%;
    }
    .searchContainer {
        flex-direction: column;
        padding: 0 20px;
        width: auto;
    }
    .search {
        flex: 1;
    }
    .searchIcon {
        position: absolute;
        left: 32px;
        top: 18%;
        transform: translateY(-50%);
    }
    .inputContainer {
        width: 100%;
    }
    .roleFlex {
        width: 100% !important;
        padding: 0 20px;
    }
    .roleContainer {
        min-width: 100%;
        min-height: auto;
    }
    .buttonContainer {
        background-color: $white;
        bottom: 0;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .confirmButton {
        width: 70%;
    }
    .confirmButtonDrawer {
        width: 100%;
    }
}
