@import "../../../../../css/helpers/colors.scss";

.wrapper {
    overflow-y: auto;
    height: 100vh;
    margin-bottom: 80px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    padding-top: 7px;
}

.title {
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey900;
}

.content {
    padding: 0 16px;
    margin: 24px auto;
}

.card {
    cursor: pointer;

    border: 1px solid;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $grey900;
}

.cardNo {
    background: $grey25;
    border-color: $grey200;
}

.cardFlex {
    display: flex;
    justify-content: center;
    gap: 24px;
}

.greenSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $success500;
}

.redSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $error600;
}

.yellowSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $warning400;
}

.greySubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey400;
}

.cardSM {
    padding: 8px;
    flex: 1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $grey900;
}

.resultSubtext {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $grey500;
}

.resultGreen {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: $success600;
}

.orange {
    color: #f79009;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.sliderContainer {
    margin-bottom: 32px;
}

.prevbutton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.botMobileContainer {
    position: fixed;
    right: 76px;
    bottom: 152px;
}

.selectedCard {
    border: 1px solid #faa91a;
    background: #fff3dd;
}
