@import "../../css/helpers/colors.scss";

.resourceTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .resourceLeft {
        h2 {
            color: $grey900;
            font-family: PlusJakartaSans;
            font-size: 24px;
            font-weight: 700;
        }

        p {
            color: $grey500;
            font-family: PlusJakartaSans;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .resourceRight {
        display: flex;
        gap: 40px;
        align-items: center;
        h1 {
            color: $grey800;
            font-family: PlusJakartaSans;
            font-size: 30px;
            font-weight: 700;
            span {
                color: $grey500;
                font-family: PlusJakartaSans;
                font-size: 16px;
                font-weight: 400;
            }
        }

        button {
            border-radius: 12px;
            border: 1px solid $error300;
            background: $error25;
            padding: 8px;
            cursor: pointer;
        }
    }
}

.resourceTab {
    position: relative;
    padding: 16px 8px;
    color: $grey600;
    cursor: pointer;
    flex: 1;

    &--selected {
        border-bottom: 3px solid $Primary;
        background: linear-gradient(
            181deg,
            rgba(255, 255, 255, 0) 1.14%,
            rgba(250, 169, 26, 0.2) 98.85%
        );
        border-radius: 12px;
    }
}

.resourceTitle {
    white-space: nowrap;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
}

.resourceSubtitle {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.setDetailsBtn {
    display: inline-block;
    padding: 4px 12px;
    background: $grey25;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid $grey200;
    border-radius: 6px;
    color: #000;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.resourceTabWrapper {
    display: flex;
    gap: 40px;

    .info {
        display: flex;
        gap: 12px;
        min-width: 223px;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border: 1px solid $grey200;
            border-radius: 50%;
            color: $Primary;
            &.selected {
                background-color: $Primary;
                border: none;
                color: $white;
            }
        }
        .title {
            color: $grey800;
            font-family: "PlusJakartaSans";
            font-size: 14px;
            font-weight: 700;
        }
        .detailsConatiner {
            display: flex;
            gap: 16px;
            .details {
                color: $grey600;
                font-family: "PlusJakartaSans";
                font-size: 14px;
                font-weight: 400;
            }
            .money {
                color: $grey600;
                font-family: "PlusJakartaSans";
                font-size: 14px;
                font-weight: 700;
                white-space: nowrap;
                span {
                    color: $grey500;
                    font-family: "PlusJakartaSans";
                    font-size: 10px;
                    font-weight: 400;
                }
                &.increase {
                    color: $success600;
                }
            }
        }
    }
}

.nextIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 0;
    background: $grey50;
    border: 1px solid $grey200;
    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
}

.wrapper {
    position: relative;
    margin-bottom: 52px;
}

.modalSubtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey500;
}

.modalResource {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background: $warning50;
    border: 1px solid $warning200;
    border-radius: 8px;
}

.modalResources {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modalBtn {
    padding: 4px 16px !important;
    border-radius: 4px !important;
    font-family: "PlusJakartaSans" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.secondaryBtn {
    background-color: #fff !important;
}

.modalButtons {
    display: flex;
    gap: 16px;
}

.deleteButton {
    background-color: $error500 !important;
}

@media only screen and (max-width: 767px) {
    .resourceTitleContainer {
        display: none;
    }
}
