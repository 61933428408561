$white: #ffffff;

$grey25: #fcfcfd;
$grey50: #f9fafb;
$grey100: #f2f4f7;
$grey200: #e4e7ec;
$grey300: #d0d5dd;
$grey400: #98a2b3;
$grey500: #667085;
$grey600: #475467;
$grey700: #344054;
$grey800: #1d2939;
$grey900: #101828;

$warning25: #fffcf5;
$warning50: #fffaeb;
$warning100: #fef0c7;
$warning200: #fedf89;
$warning300: #fec84b;
$warning400: #fdb022;
$warning500: #f79009;
$warning600: #dc6803;

$success25: #f6fef9;
$success50: #ecfdf3;
$success200: #a6f4c5;
$success400: #32d583;
$success500: #12b76a;
$success600: #039855;
$success700: #027a48;

$error25: #FFFBFA;
$error100: #fee4e2;
$error300: #fda29b;
$error500: #f04438;
$error600: #d92d20;
$error700: #B42318;

$blue600: #1570ef;

$orange50: #fff6ed;
$orange200: #fddcab;
$orange400: #fd853a;

$bluelight50: #f0f9ff;
$bluelight200: #b9e6fe;
$bluelight500: #0ba5ec;

$purple50: #f9f5ff;
$purple200: #e9d7fe;
$purple500: #9e77ed;

$indigo200: #c7d7fe;
$indigo500: #6172f3;

$primary100: #f4ebff;
$primary300: #bbcbfb;

$Primary: #FAA91A