@import "../../css/helpers/colors.scss";

.selectedRole {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 367px;
    margin-bottom: 16px;
    text-align: left;
}

.selectedRoleLeft {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
        width: 42px;
        height: 42px;
    }
    .selectedRoleInfo {
        p {
            color: $grey800;
            font-family: "PlusJakartaSans";
            font-weight: 400;
            text-align: left;
        }
        small {
            color: $grey700;
            font-size: 14px;
            font-weight: 400;

            span {
                color: $grey400;
                font-size: 12px;
            }
        }
    }
}

.trsahBtn {
    border: none !important;
    background: transparent;
    padding: 8px;
    cursor: pointer;
}

.selectBtn {
    background-color: $grey100;
    border-radius: 12px;
    border: none;
    padding: 6px 16px;
    color: $grey600;
    /* Text xs/Normal */
    font-family: "PlusJakartaSans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    &.selected {
        color: $white;
        background-color: $Primary;
    }
}

@media only screen and (max-width: 767px) {
    .selectedRole {
        min-width: 100%;
    }
}
