@import "../../../../css/helpers/colors.scss";

.logo {
    margin-bottom: 140px;
}

.wrapper {
    min-height: 100%;
    padding-top: 78px;
    display: flex;
    flex-direction: column;
}

.mainConatiner {
    flex: 1;
    padding: 32px;
    padding-left: 120px;
    padding-right: 120px;
    position: relative;
    display: flex;
    flex-direction: column;

    // .innerConatiner {
    //     display: flex;
    //     flex: 1;
    //     justify-content: space-around;
    //     .leftButton {
    //         button {
    //             visibility: hidden;
    //         }
    //     }
    //     .rightButton,
    //     .leftButton {
    //         align-self: center;
    //     }
    // }
}

.warning {
    width: fit-content;
    padding: 12px 16px;
    font-size: 12px;
    margin: 0 auto;
    color: $grey600;
    border-radius: 12px;
    border: 1px solid rgba(250, 169, 26, 0.4);
    background: #fff7e8;
}

.hidden {
    visibility: hidden;
}

.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey900;
    text-align: center;
}

.header {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        color: $grey500;
        font-size: 12px;
    }

    h6 {
        font-family: "PlusJakartaSans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: $grey500;

        .black,
        .green {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
        }
        .black {
            color: #000;
        }

        .green {
            color: $success600;
        }
    }
}
.doneButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.closeBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.logoFlex {
    display: flex;
    align-items: center;
    gap: 16px;
}

.subtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey500;
    margin-left: 12px;
}

.experienceRow {
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    .orange {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $warning500;
    }
}

.experiencePriceBlock {
    margin-top: 48px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.sliderLimit {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    display: inline-block;
    top: calc(-100% + 10px);
}

.sliderContainer {
    position: relative;
    width: 60%;
    margin: 0 auto 32px;
    margin-bottom: 256px;
}

.sliderLimitLeft {
    left: 0;
    transform: translateX(-50%);
}

.sliderLimitRight {
    right: 0;
    transform: translateX(50%);
}

.resourceCarouselWrapper {
    margin-bottom: 14px;
}

.bottomLine {
    top: 100%;
}
.doneButtonMobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding-top: 48px;
    }
    .mainConatiner {
        padding: 10px 0px 0px 20px;
    }
    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 0px;
        margin-top: 30px;
    }
    .doneButton {
        display: none;
    }
    .doneButtonMobile {
        display: block;
    }
}
