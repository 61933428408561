@import "../../../../css/helpers/colors.scss";

.logo {
    margin-bottom: 140px;
}

.wrapper {
    padding: 45px 56px;
    height: 100vh;
    overflow-y: auto;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: $grey900;
}

.header {
    margin-bottom: 67px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.logoFlex {
    display: flex;
    align-items: center;
    gap: 16px;
}

.buttonFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resourceCarouselWrapper {
    margin-bottom: 14px;
}

.flexSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orange {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $warning400;
}

.resourceTitle {
    margin-bottom: 48px;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding: 0 50px;
    margin-bottom: 32px;
}

.card {
    background-color: $warning25;
    border: 1px solid $warning300;
    border-radius: 8px;
    padding: 16px;
    flex-basis: calc(33% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    text-align: center;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.boldOrange {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $warning600;
}

.subtitle {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
    margin-bottom: 12px;
}

.timelineContainer {
    height: 32px;
    background-color: $grey100;
    border: 1px solid $grey200;
    border-radius: 4px;
    position: relative;
}

.timeline {
    background-color: #faa91a;
    position: absolute;
    top: 0;
    bottom: 0;
}

.timelineNumbers {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: $grey500;
}

.timelineWrapper {
    margin-bottom: 32px;
    padding: 0 32px;
}

.totalResults {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
}

.subtext {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.resultBold {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $grey900;
}

.modalBtns {
    gap: 16px;
    padding: 16px 0;
}
