@import "../../../../css/helpers/colors.scss";

.userCardsNarrow {
    flex: 1;
    margin-top: 71px;
}
.userCards {
    flex: 2;
    margin-top: 71px;
}

@media only screen and (max-width: 767px) {
    .userCards {
        flex: 7;
        margin-top: 50px;
    }
}
