@import "../../css/helpers/colors.scss";

.closeBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    color: $grey600;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.modalText {
    font-family: "PlusJakartaSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey500;
}

.modalButtons {
    display: flex;
    gap: 16px;
}

.customModal {
    img {
        margin-bottom: 24px;
    }
    h2 {
        color: $grey900;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 24px;
    }
}
