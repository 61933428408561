@import "../../../../../css/helpers/colors.scss";

.logo {
    margin-bottom: 140px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.questionContainer {
    padding-left: 25px;
    position: relative;
    &::before {
        content: "1.";
        line-height: 2;
        position: absolute;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        color: $grey500;
        left: 0;
        top: 0;
    }
}

.title {
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: $grey700;
    margin-bottom: 24px;
}

.header {
    margin-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.optionContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 64px;
}

.option {
    display: inline-block;
    padding: 6px 24px;
    background: $grey25;
    border: 1px solid $grey200;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
    cursor: pointer;
}

.inputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    width: fit-content;
    border-radius: 12px;
    background-color: $grey50;
    padding: 14px 12px;

    & input {
        margin-left: 12px;
    }
}

.primaryButton {
    width: 86px !important;
    height: 38px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0 !important;
    border-radius: 12px !important;
    font-family: "PlusJakartaSans" !important;
}

@media only screen and (max-width: 767px) {
    .title {
        font-size: 18px;
    }

    .questionContainer {
        padding-left: 0px;
        position: relative;
        &::before {
            position: relative;
        }
    }
}
